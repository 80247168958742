import React from "react"
const LargeVideo = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video">
    <video
      controls
      src={videoSrcURL}
      type="video/mp4"
      title={videoTitle}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      style={{width:'calc(100px + 55vw)', height:"calc(80px + 30vw)"}}
    />
  </div>
)
export default LargeVideo