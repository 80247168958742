import React from "react"
import styled from "styled-components"

const Indented = styled.div`
  margin-left: 20px;
`

const GuardianshipFAQAccordion = () => {
  return (
    <div id="accordion">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is a Guardian?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              A Guardian is the person or company parents appoint to take
              responsibility for their child in the UK. They act for parents to
              ensure the well-being and academic progress of their child whilst
              they are studying in the UK.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Why do I need to appoint a Guardian?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <ul class="accordion-body">
              <li>
                The UK Border Agency and UK boarding schools require every
                student under the age of 16 to have an educational guardian who
                lives in the UK.
              </li>
              <li>
                Schools usually also require students aged over 16 to have a
                UK-based guardian.
              </li>
              <li>
                Parents need someone to be responsible for their child when they
                are:
                <Indented>
                  - In school: to ensure that their studies and school life are
                  going well.
                </Indented>
                <Indented>
                  - Out of school: whilst travelling and during weekends and
                  holidays, to ensure they are safe, cared for and comfortable.
                </Indented>
              </li>
              <li>
                An experienced, caring Guardianship company such as Study Links
                will take care of practical details and other concerns so
                parents can have peace of mind and students can enjoy their
                studies.
              </li>
            </ul>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              What can Study Links do for us?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <ul class="accordion-body">
              <li>
                Provide a 24/7 emergency contact number and act quickly and
                expertly for students and parents in any difficult
                circumstances.
              </li>
              <li>
                Be available to contact and advise for any queries, concerns and
                uncertainties students or parents may have.
              </li>
              <li>
                Keep in contact with the student, their parents, their agent and
                school so that everything runs smoothly.
              </li>
              <li>
                Match your child to a welcoming, friendly homestay family to
                stay with when they are out of school who have been carefully
                trained and checked to meet high standards.
              </li>
              <li>
                Arrange safe travel in the UK to and from school, homestays,
                airports.
              </li>
              <li>
                Arrange action plans and give advice to help students in their
                studies and with future plans.
              </li>
            </ul>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              How does Study Links work?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div>
                Study Links is a family-run company where all the members work
                together as a team to provide Guardianship services for your
                family. Some of our team members work in our office, some will
                visit students in person at school.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              How should we contact Study Links?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul class="card-body">
                <li>
                  The best way to contact us is by email:{" "}
                  <a href={"mailto:office@studylinks.co.uk"}>
                    office@studylinks.co.uk
                  </a>
                </li>
                <li>
                  You can telephone us on{" "}
                  <a href="tel: +44 0127 4679710">+44 (0)1274 679710</a> during
                  office hours 9–5pm UK time, Monday to Friday
                </li>
                <li>
                  Our emergency number is{" "}
                  <a href="tel: +44 7884 101679">+44 7884 101679</a>
                </li>
                <li>
                  Please ring the emergency number if you need help immediately
                  and the office isn’t open.
                </li>
                <li>
                  We may contact you on Whatsapp and Wechat but our main
                  communication channel is via email. Please check your email
                  frequently for important messages.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Can Study Links provide guardianship at any school/university?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div>
                Yes, Study Links provide guardianship throughout the UK. We have
                members of staff and homestay around the country, to assist you
                in any emergency.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              How should we prepare for our child studying in the UK?
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div>
                Study Links provide parents and students with a detailed guide
                on how to prepare for their UK studies. As your guardian, we are
                here to support your family.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEight">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Can Study Links provide accommodation during holidays?
            </button>
          </h2>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div>
                Yes. Study Links provides homestay accommodation for students
                during school holidays. Study Links can also arrange holiday
                camps if requested.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingNine">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              What additional services can Study Links provide guardianship
              students?
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul class="card-body">
                <li>
                  Study Links offers many additional services for guardianship
                  students and their families. Full details will be sent during
                  registration. Some examples include:
                </li>
                <li>Private tutoring</li>
                <li>
                  Specialist university application advice, including Oxbridge
                  Preparation, Medicine, Law
                </li>
                <li>UK-trip arrangements for families</li>
                <li>Airport chaperone service</li>
                <li>Airport transfers</li>
                <li>Homestay accommodation</li>
                <li>
                  Holiday camps (e.g. summer camp; Easter camp; revision camps)
                </li>
                <li>Integration workshops</li>
                <li>Study skills sessions</li>
                <li>Conversational English classes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuardianshipFAQAccordion
