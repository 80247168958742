import styled from "styled-components"
import { Link } from "gatsby";

export const CardsItem = styled.div`
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  justify-self: center;
`

export const CardsItemLink = styled(Link)`
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
`

export const CardsItemPicWrap = styled.figure`
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;

  &:after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: calc(10px + 0.5vw);
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
`

export const CardsItemImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
  }
`

export const CardsItemInfo = styled.div`
  padding: 1.5vw 1.5vw;
`

export const CardsItemText = styled.h5 `
  color: #252e48;
  font-size: calc(0.3vw + 15px);
  line-height: 25px;
  text-align: center;
`

export const CardGrid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 60px;
  row-gap: 100px;

  @media only screen and (max-width: 2080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 100px;

  }
  @media only screen and (max-width: 1660px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 50px;


  }

  @media only screen and (max-width: 1240px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;


  }

  @media only screen and (max-width: 820px) {
    row-gap: 30px;
    grid-template-columns: 1fr;
  }
`

export const CardWrapper = styled.div `
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 820px) {
    width: calc(200px + 20vw);
    height: calc(200px + 20vw);
  }
`