import React from 'react'
import Layout from "../layout/layout";
import ReactHtmlParser from "react-html-parser"
import styled from 'styled-components'
import Fade from "react-reveal/Fade"
import GuardianshipFAQAccordion from '../components/Accordions/GuardianshipFAQ';
import GuardianshipVideo from '../video/Guardianship.mp4'
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton";
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements";
import {guardianshipGif as Gif} from '../images/'
import { GuardianshipTestimonialCarousel, InsertedTestimony } from "../components/Testimonials";
import { guardianshipApply, guardianshipContact, guardianshipBanner } from "../images/";
import PricingPDF from "../PDF/guardianshipPrice.pdf";
import BrochurePDF from "../PDF/guardianshipBrochure.pdf";
import { CentreWrapper, GreenButton } from '../components/ButtonElement';
import SEO from '../components/seo';
import { graphql } from "gatsby"


const VideoContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

`

const GifContainer = styled.img `
  width: 100%;
  height: auto;
  align-self: center;

  @media (min-width: 2000px) {
    width: 1600px;
  }
`

const FeeButtonsWrapper = styled.div `
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: 'educationalGuardian',
    title: 'Educational Guardian'
  },
  {
    to: 'ourService',
    title: 'Our Service'
  },
  {
    to: 'fees',
    title: 'Fees'
  },
  {
    to: 'apply',
    title: 'Apply'
  },
  {
    to: 'faq',
    title: 'FAQ'
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br/>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function Guardianship({data}) {
  console.log(data)
  const html = data.allWpPage.edges
  const overview = ReactHtmlParser(html[2].node.content)
  const ourService = ReactHtmlParser(html[0].node.content)
  const fees = ReactHtmlParser(html[1].node.content)
  console.log(fees)
  return (
      <div>
        <Layout sidebar={sidebar} pageTitle="Guardianship" background={guardianshipBanner} pageDescription="Absolute peace of mind to parents and practical support to students through our independent expert advice and 24/7 emergency support">
          <SEO title="Guardianship | UK Education | Schools | University | Boarding Schools" description="Study Links provide guardianship for their international students. We offer 4 guardianship packages which can be further tailored to meet the needs of your family."/>
          <Section id="educationalGuardian">
            <FadeH1>What is an educational guardian?</FadeH1>
            <VideoContainer>
              <Video videoSrcURL={GuardianshipVideo} videoTitle="Welcome to Studylinks"/>
            </VideoContainer>
          </Section>
          <Section id="ourService">
            <FadeH1>Our Service</FadeH1>
            {ourService.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <br/>
          <GifContainer src={Gif}/>
          <br/>
          <br/>
          </Section>
          <Section id="fees">
            <FadeH1>Fees</FadeH1>
            {fees.map((data, index) => {
              return (
                <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
            <Fade>
              <FeeButtonsWrapper>
                <GreenButton href={BrochurePDF} target="_blank">Brochure PDF</GreenButton>
                <GreenButton href={PricingPDF} target="_blank">Packages PDF</GreenButton>
              </FeeButtonsWrapper>
              <br/>
              <FeeButtonsWrapper>
                <GreenButton href="/contact">Contact us for pricing</GreenButton>
              </FeeButtonsWrapper>
            </Fade>
          </Section>
          <Section id="apply">
            <FadeH1>Apply</FadeH1>
            <br/>
              <CentreWrapper>
                <CardWrapper>
                  <ButtonCardItem path='https://studylinks.force.com/Studylinks/s/guardianshipapplication' label="Apply" text="Click here to apply for guardianship" src={guardianshipApply}/>
                </CardWrapper>
                <CardWrapper>
                  <ButtonCardItem path='/contact' label="Contact Us" text="Got any questions? Leave us a message" src={guardianshipContact}/>
                </CardWrapper>
              </CentreWrapper>
          </Section>
          <Section id="faq">
            <FadeH1>FAQ</FadeH1>
            <GuardianshipFAQAccordion/>
          </Section>
          <GuardianshipTestimonialCarousel/>
        </Layout>
      </div>
  )
}

export const STUDYLINKS_GUARDIANSHIP = graphql`
  query studylinks_guardianship {
  allWpPage(filter: {slug: {in: ["api-studylinks-whatisaneducational", "api-studylinks-guardianship", "api-studylinks-guardianshipfees"]}}, sort: {fields: title, order: ASC}) {
    edges {
      node {
        title
        content
      }
    }
  }
}

`